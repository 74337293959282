import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Welcome to the documentation for Pipy.`}</p>
    <p>{`Pipy is a programmable network proxy for the cloud, edge and IoT. `}</p>
    <h1 {...{
      "id": "learning-materials"
    }}>{`Learning Materials`}</h1>
    <p>{`These instructions help you get to know Pipy and how to use it.`}</p>
    <h2 {...{
      "id": "what-is-pipy"
    }}>{`What is Pipy?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/intro/overview"
        }}>{`Overivew`}</a>{` - This section covers a general overview of what Pipy is, why Pipy.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/intro/concepts"
        }}>{`Concepts`}</a>{` - This section give you detailed overview of architectural concepts of Pipy.`}</li>
    </ul>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started/quick-start"
        }}>{`Quick Start`}</a>{` - Quickly get started with Pipy.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started/build-install"
        }}>{`Installation`}</a>{` - How to build and install Pipy from scratch.`}</li>
    </ul>
    <h2 {...{
      "id": "learning"
    }}>{`Learning`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorial/01-hello"
        }}>{`Tutorials`}</a>{` Detailed examples for practical applications with step by step approach.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/api"
        }}>{`API Reference`}</a>{` - Documentation of Pipy API.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/pjs"
        }}>{`PipyJS Reference`}</a>{` - Documentation of PipyJS - The JavaScript dialect used by Pipy.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://katacoda.com/flomesh-io"
        }}>{`Katacoda scenarios`}</a>{` interactive learning for beginners and advanced learners.`}</li>
    </ul>
    <h2 {...{
      "id": "contribute"
    }}>{`Contribute`}</h2>
    <p>{`If you want to dive into development of Pipy, these materials give you some guidance.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started/getting-help"
        }}>{`Contributing Instructions`}</a>{` - A guide on how to open issues and contribute code to the Pipy project.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/flomesh-io/pipy/blob/main/CODE_OF_CONDUCT.md"
        }}>{`Code of Conduct`}</a>{` - Our standards and expectations about working together as a community.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      